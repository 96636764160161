import React from "react"
import NewsroomHeader from "../components/Headers/NewsroomHeader/NewsroomHeader"
import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"

import Background from "../images/newsroomBackground.png"
import BackgroundMobile from "../images/newsroomBackgroundMobile.png"
import Newsroom from "../components/Newsroom/Newsroom"

interface Context {
  id: string;
  title: string;
  body: string;
  date: string;
  thumbnail: string;
  related: { id: string; title: string; thumbnail: string; }[];
}

const NewsPost: React.FC<{ pageContext: Context }> = ({ pageContext: context }) => {
  return (
    <div>
      <Navbar />
      <NewsroomHeader
        title={context.title}
        date={context.date}
        background={Background}
        backgroundMobile={BackgroundMobile}
      />
      <article className="w-full h-auto flex items-center justify-center bg-white">
        <Newsroom body={context.body} related={context.related} />
      </article>
      <Footer />
    </div>
  )
}

export default NewsPost
