import React from "react";
import SideListItem from "./SideListItem";
import ButtonIcon from "../../images/left-arrow.png";
import { Link } from "gatsby";
import TwitterIcon from "../../images/tw-icon-tr.png";

interface Props {
  body: string;
  related: { id: string; title: string; thumbnail: string; }[];
}

const Newsroom: React.FC<Props> = ({ body, related }) => {
  return (
    <div className=" flex lg:flex-row flex-col lg:w-[90%] w-[100%] justify-center">
      <section className="lg:w-[50%] w-[100%] lg:p-16 p-5">
        <Link
          to="/newsroom"
          className="font-bold text-slate-700 pb-10 flex flex-row justify-arround items-center"
        >
          <img src={ButtonIcon} className="w-4 mr-2" />
          Back to all news
        </Link>
        <div className="opacity-90 prose lg:prose-lg max-w-none" dangerouslySetInnerHTML={{ __html: body }} />

        <div className="w-10 h-fit mt-8 flex items-center justify-center">
          <a href="https://twitter.com/intermetaglobal" rel="noreferrer noopener" target="_blank">
            <img src={TwitterIcon} />
          </a>
        </div>
      </section>
      <section className="lg:w-[30%] w-[100%] flex flex-col pb-10">
        {related.length > 0 && <h3 className="font-bold pb-5 lg:pt-32 pt-10 text-3xl tracking-wide lg:ml-28 ml-6">
          Related news
        </h3>}
        {related.map((item, index) => (
          <Link to={`/posts/${item.id}`} key={index}>
            <SideListItem
              IMGSrc={item.thumbnail}
              Title={item.title}
            />
          </Link>
        ))}
      </section>
    </div>
  );
};

export default Newsroom;
