import React from "react"

interface SideListItemProps {
  IMGSrc: string
  Title: string
}

const SideListItem = ({ IMGSrc, Title }: SideListItemProps) => {
  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col lg:w-[70%] w-[90%] lg:items-center justify-center mb-10">
        <div className="max-w-sm lg:w-[80%] w-[100%] aspect-[5/4] bg-blue-300 rounded-tr-[10%] rounded-bl-[10%] overflow-hidden">
          <img src={IMGSrc} className="w-full h-full object-cover" />
        </div>
        <p className="lg:w-[80%] w-[100%] pt-5 lg:text-gray-600 text-black lg:text-lg text-xl lg:tracking-wide font-extrabold">
          {Title}
        </p>
      </div>
    </div>
  )
}

export default SideListItem
