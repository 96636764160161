import React from "react"
import useMediaQuery from "../../../hooks/useMediaQuery"

interface NewsroomHeaderProps {
  title: string
  date: string
  background: string
  backgroundMobile: string
}

const NewsroomHeader = ({
  title,
  date,
  background,
  backgroundMobile,
}: NewsroomHeaderProps) => {
  const isDesktop = useMediaQuery("(min-width: 1400px)")

  return (
    <div>
      <div className="h-[10%] flex flex-col justify-center items-center">
        {isDesktop ? (
          <img
            className="absolute h-screen lg:h-[70vh] top-0 -z-20 w-full scale-120 object-cover"
            src={background}
          />
        ) : (
          <img
            className="absolute h-screen lg:h-[100vh] top-0 -z-20 w-full scale-120 object-cover"
            src={background}
          />
        )}

        <h1 className="text-white text-3xl md:text-5xl lg:text-6xl font-bold text-center tracking-wide pb-20 mt-48 lg:w-[50%] w-[90%]">
          {title}
        </h1>
        <p className="text-white text-md lg:text-lg font-light text-center tracking-wide pb-10">
          {date}
        </p>
      </div>
    </div>
  )
}

export default NewsroomHeader
